import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { EngineeringScopingState } from '../../reducers/engineering/scoping.reducer';

const engineeringScopingState = (state: AppState): EngineeringScopingState =>
  state.engineering.scoping;

export namespace ScopingSelectors {
  export const getScopingBrowseData = createSelector(
    [engineeringScopingState],
    ({ scopingBrowseData }: EngineeringScopingState) => scopingBrowseData,
  );

  export const getScopingSiteInfo = createSelector(
    [engineeringScopingState],
    ({ scopingSiteInfo }: EngineeringScopingState) => scopingSiteInfo,
  );

  export const getScopingEquipmentData = createSelector(
    [engineeringScopingState],
    ({
      currentProjectId,
      scopingInfo,
      scopingEngineeringData: { RFDSPhase1, regulatoryCompliance },
      otherEquipments,
      scopingSectors,
      scopingLeasingInfo,
    }: EngineeringScopingState) => ({
      currentProjectId,
      scopingInfo,
      RFDSPhase1,
      regulatoryCompliance,
      otherEquipments,
      scopingSectors,
      scopingLeasingInfo,
    }),
  );

  export const getCurrentModalType = createSelector(
    [engineeringScopingState],
    ({ currentModalType }: EngineeringScopingState) => currentModalType,
  );

  export const getScopingSummaryData = createSelector(
    [engineeringScopingState],
    ({
      scopingSiteInfo,
      scopingProjectInfo,
      scopingLeasingInfo,
      currentProjectId,
      scopingInfo,
      scopingSiteAuditData,
      scheduledScopingDate,
    }: EngineeringScopingState) => ({
      scopingSiteInfo,
      scopingProjectInfo,
      scopingLeasingInfo,
      currentProjectId,
      scopingInfo,
      scopingSiteAuditData,
      scheduledScopingDate,
    }),
  );

  export const getScopingLeaseEquipmentData = createSelector(
    [engineeringScopingState],
    ({
      currentProjectId: id,
      equipmentTypeNames,
      leasingEquipmentData: {
        curPriorTableData,
        curFinalTableData,
        scopingTableData,
        priorTableData,
        finalTableData,
        scopingNotes,
        priorLeasingNote,
        finalLeasingNote,
      },
    }: EngineeringScopingState) => ({
      id,
      equipmentTypeNames,
      curPriorTableData,
      curFinalTableData,
      priorTableData,
      finalTableData,
      scopingTableData,
      scopingNotes,
      priorLeasingNote,
      finalLeasingNote,
    }),
  );

  export const getProjectType = createSelector(
    [engineeringScopingState],
    (state: EngineeringScopingState) => state.projectType.value,
  );

  export const isFetching = createSelector(
    [engineeringScopingState],
    (state: EngineeringScopingState) => state.isFetching,
  );
}
