import React, {
  FC,
  PropsWithChildren,
  ReactText,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { Store } from 'antd/lib/form/interface';
import classNames from 'classnames';
import { HttpService, ObjectComparatorService } from '@core/services';
import { EquipmentHttpService, LeasingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { wrappedTextRender } from '@core/utils/methods';
import {
  ActionRes,
  EditableComponentProps,
  EquipmentModal,
} from '@models/interfaces';
import { NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions, LeasingActions } from '@store/actions';
import { LeasingSelectors, ProjectFuzeIDsSelectors } from '@store/selectors';
import {
  AssociatedProjectsModal,
  LeasingEquipmentNotes,
  LeasingEquipmentSection,
} from './components';

import './styles.scss';

const { useForm } = Form;

export const LeasingEquipment: FC<EditableComponentProps> = ({
  isEditing,
  toggleEditing,
  data: {
    equipmentTypeNames,
    id,
    curPriorTableData,
    curFinalTableData,
    priorTableData,
    finalTableData,
    scopingTableData,
    scopingNotes,
    priorLeasingNote,
    finalLeasingNote,
  },
}: PropsWithChildren<EditableComponentProps>) => {
  const projectFuzeIDsTableVisible = useSelector(
    ProjectFuzeIDsSelectors.getProjectFuzeIDsTableVisible,
  );
  const leasingFetching = useSelector(LeasingSelectors.isFetching);

  const dispatch = useDispatch();

  const [priorForm] = useForm();
  const [scopingForm] = useForm();
  const [finalForm] = useForm();

  const [notesForm, setNotesForm] = useState<Store[]>([]);
  const [initialForm, setInitialForm] = useState<Store[]>([]);

  const notesCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    notesForm,
    true,
  );

  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [priorData, setPriorData] = useState<EquipmentModal[]>();
  const [scopingData, setScopingData] = useState<EquipmentModal[]>();
  const [finalData, setFinalData] = useState<EquipmentModal[]>();
  const [isAssociatedProjectsModalVisible, setAssociatedProjectsModalVisible] =
    useState<boolean>(false);

  const toggleAssociatedProjectsModal = (): void => {
    setAssociatedProjectsModalVisible(!isAssociatedProjectsModalVisible);
  };

  useEffect(() => {
    if (equipmentTypeNames.length) {
      HttpService.getHttpRequests(EquipmentHttpService).getEquipmentsByTypes(
        equipmentTypeNames,
      );
    }
  }, [equipmentTypeNames]);

  useEffect(() => {
    setPriorData(curPriorTableData);
    setFinalData(curFinalTableData);
    setScopingData(scopingTableData);
  }, [curPriorTableData, curFinalTableData, scopingTableData]);

  useEffect(() => {
    priorForm.setFieldsValue({
      priorLeasingNote,
    });
  }, [priorLeasingNote]);

  useEffect(() => {
    finalForm.setFieldsValue({
      finalLeasingNote,
    });
  }, [finalLeasingNote]);

  useEffect(() => {
    scopingForm.setFieldsValue({ scopingTowerNote: scopingNotes });
  }, [scopingNotes]);

  const transformToCurrentData = (
    data: EquipmentModal[],
  ): Array<EquipmentModal> =>
    data.map(
      ({
        modelNumber,
        equipmentTypeName,
        generatedId,
        manufacturer,
        ...leasingEquipmentUpdateData
      }: EquipmentModal) => ({
        ...leasingEquipmentUpdateData,
      }),
    );

  const isDataNotUpdated =
    notesCompare &&
    ObjectComparatorService.arraysCompare(
      transformToCurrentData(curPriorTableData),
      priorTableData,
    ) &&
    ObjectComparatorService.arraysCompare(
      transformToCurrentData(curFinalTableData),
      finalTableData,
    );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!isDataNotUpdated));
  }, [
    curPriorTableData,
    curFinalTableData,
    priorTableData,
    finalTableData,
    notesCompare,
  ]);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  const onSubmit = async (): Promise<void> => {
    try {
      await HttpService.getHttpRequests(
        LeasingHttpService,
      ).updateLeasingEquipment(id, {
        priorTableData: transformToCurrentData(
          curPriorTableData.map((equipment: EquipmentModal) => ({
            ...equipment,
            isLeaseRight: selectedRows.includes(equipment.generatedId || ''),
          })),
        ),
        finalTableData: transformToCurrentData([
          ...curFinalTableData,
          ...curPriorTableData
            .map((priorEquipment: EquipmentModal) => ({
              ...priorEquipment,
              isLeaseRight: selectedRows.includes(
                priorEquipment.generatedId || '',
              ),
            }))
            .filter(
              (priorEquipment: EquipmentModal) =>
                !curFinalTableData.find(
                  (finalEquipment: EquipmentModal) =>
                    priorEquipment.equipment === finalEquipment.equipment,
                ) && priorEquipment.isLeaseRight,
            ),
        ]),
        priorLeasingNote: priorForm.getFieldsValue().priorLeasingNote,
        finalLeasingNote: finalForm.getFieldsValue().finalLeasingNote,
      });

      setInitialForm(notesForm);

      NotificationsLoader.notificationSuccess(
        'Leasing Equipment Information has been updated!',
      );

      toggleEditing?.();
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = (): void => {
    setNotesForm(initialForm);

    dispatch(LeasingActions.resetTablesDataAction.done());
    priorForm.setFieldsValue({
      priorLeasingNote,
    });
    finalForm.setFieldsValue({
      finalLeasingNote,
    });
    toggleEditing?.();
  };

  const onNotesChange = (): void => {
    if (Object.keys(priorForm.getFieldsValue()).length) {
      const data = [priorForm.getFieldsValue(), finalForm.getFieldsValue()];

      setNotesForm(data);

      if (!Object.keys(initialForm).length) {
        setInitialForm([{ priorLeasingNote }, { finalLeasingNote }]);
      }
    }
  };

  const buttonsDisabled = leasingFetching || isDataNotUpdated;

  useSaveChanged(isEditing, onSubmit, onCancel);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (rows: ReactText[]): void => setSelectedRows(rows),
  };

  useEffect(() => {
    setSelectedRows(
      curPriorTableData
        .filter((equipment: EquipmentModal) => equipment?.isLeaseRight)
        .map((equipment: EquipmentModal) => equipment?.generatedId || ''),
    );
  }, [curPriorTableData, isEditing]);

  return (
    <div className="prov-leasing-equipment">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'fuze-ids-table-open': projectFuzeIDsTableVisible,
        })}
      >
        {isEditing && (
          <Row className="prov-leasing-equipment__handler">
            <PrimaryButton
              title="Set prior project"
              onClick={toggleAssociatedProjectsModal}
            />
            <PrimaryButton
              title="Generate final loading"
              onClick={(): ActionRes<ReactText[]> =>
                dispatch(
                  LeasingActions.generateFinalLoadingAction.done(selectedRows),
                )
              }
              disabled={!priorData?.length && !scopingData?.length}
            />
            <AssociatedProjectsModal
              visible={isAssociatedProjectsModalVisible}
              toggleModal={toggleAssociatedProjectsModal}
              projectId={id}
            />
          </Row>
        )}
        <Row className="bottom-row">
          <Col className="bottom-row__col">
            <LeasingEquipmentSection
              header="Final Project Loading"
              isEditing={isEditing}
              data={finalData}
              className="final-project-loading-section"
              sectionStorePath="curFinalTableData"
            />
            <LeasingEquipmentNotes
              label="Final Notes"
              id="finalLeasingNote"
              isEditing={isEditing}
              form={finalForm}
              onChanges={onNotesChange}
            />
          </Col>
        </Row>
        <Row className="top-row">
          <LeasingEquipmentSection
            header="Prior Lease Right Totals"
            isEditing={isEditing}
            data={priorData}
            sectionStorePath="curPriorTableData"
            tableProps={{
              rowSelection: isEditing ? rowSelection : undefined,
            }}
          />
          <LeasingEquipmentSection
            header="Scoping Equipment Totals"
            isEditing={false}
            data={scopingData}
            sectionStorePath="scopingTableData"
          />
        </Row>
        <Row className="notes-row">
          <LeasingEquipmentNotes
            label="Prior Leasing Notes"
            id="priorLeasingNote"
            isEditing={isEditing}
            form={priorForm}
            onChanges={onNotesChange}
          />
          <LeasingEquipmentNotes
            label="Scoping Notes"
            id="scopingTowerNote"
            isEditing={false}
            form={scopingForm}
            readOnlyElementRender={wrappedTextRender}
          />
        </Row>
      </div>
      {isEditing && (
        <Row className="prov-site-information__btn-wrap">
          <div>
            <PrimaryButton
              htmlType="submit"
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmit}
            />
            <PrimaryButton
              title="Cancel"
              disabled={buttonsDisabled}
              type="default"
              onClick={onCancel}
            />
          </div>
        </Row>
      )}
    </div>
  );
};
