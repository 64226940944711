import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HttpService } from '@core/services';
import { PaceTaskHttpService } from '@core/services/http';
import { PaceTaskTabScreens } from '@models/enums';
import {
  PaceTaskScreen,
  PaceTaskScreenResponse,
  TabComponentProps,
} from '@models/interfaces';
import { PaceTasks } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { PaceTaskSelectors } from '@store/selectors';
import { AddExistsPaceTaskModal, ArchiveModal } from '../../modals';
import { paceTaskColumns } from './models/constants';

import './styles.scss';

export const PagePaceTask: FC<TabComponentProps> = ({
  isActive,
}: PropsWithChildren<TabComponentProps>) => {
  const paceTaskService = useMemo(
    () => HttpService.getHttpRequests(PaceTaskHttpService),
    [],
  );

  const { pathname } = useLocation();
  const screen: PaceTaskTabScreens = pathname
    .split('/')
    .pop() as PaceTaskTabScreens;
  const [archiveModalVisible, setArchiveModalVisible] =
    useState<boolean>(false);
  const [addPaceTaskModalVisible, setAddPaceTaskModalVisible] =
    useState<boolean>(false);
  const screenData = useSelector(PaceTaskSelectors.getScreenData(screen));
  const archivePaceTask = (
    paceTaskScreen: PaceTaskScreen,
  ): Promise<PaceTaskScreenResponse> =>
    paceTaskService.movePaceTaskToArchive(screen, paceTaskScreen);

  useEffect(() => {
    if (isActive) {
      paceTaskService.getScreenPaceTasks(screen);
    }
  }, [isActive, screen]);

  const toggleAddPaceTaskModal = (): void =>
    setAddPaceTaskModalVisible(!addPaceTaskModalVisible);
  const toggleArchiveModal = (): void =>
    setArchiveModalVisible(!archiveModalVisible);
  const moveRow = (fromIndex: number, toIndex: number): void => {
    const row: PaceTaskScreen = screenData[fromIndex];

    paceTaskService.updatePaceTaskScreen(screen, {
      ...row,
      order: toIndex + 1,
    });
  };

  return (
    <>
      <ArchiveModal
        columns={paceTaskColumns({ isArchived: true })}
        screen={screen}
        onOk={toggleArchiveModal}
        onCancel={toggleArchiveModal}
        visible={archiveModalVisible}
      />
      <AddExistsPaceTaskModal
        screen={screen}
        visible={addPaceTaskModalVisible}
        onOk={toggleAddPaceTaskModal}
        onCancel={toggleAddPaceTaskModal}
      />
      <PaceTasks
        className="prov-pace-task-screen"
        columns={paceTaskColumns({
          archivePaceTask,
        })}
        data={screenData}
        moveRow={moveRow}
        infoRowLeftElement={
          <PrimaryButton
            title="Add Task"
            icon="+"
            onClick={toggleAddPaceTaskModal}
          />
        }
        infoRowRightElement={
          <PrimaryButton title="Archive" onClick={toggleArchiveModal} />
        }
      />
    </>
  );
};
