import { RADField } from '@symfa-inc/providence-verizon-types';
import { createReducer, on } from '../../core/utils/store';
import { RAD_DATA } from '../../models/constants/rad-data';
import { PayloadAndState, RadData } from '../../models/interfaces';
import { ProjectActions } from '../actions/project.actions';

export interface RADState {
  RAD: RadData[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: RADState = {
  RAD: RAD_DATA,
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  on(
    ProjectActions.setRADAction,
    ({ payload }: PayloadAndState<RadData[], RADState>) => ({
      RAD: Object.keys(RADField).reduce(
        (acc: RadData[], curItem: string) => [
          ...acc,
          {
            RADField: curItem as RADField,
            leased: 0,
            RFDS: 0,
            scoping: 0,
            structural: 0,
            ...payload.find(
              (RADDataItem: RadData) => RADDataItem.RADField === curItem,
            ),
          },
        ],
        [],
      ),
    }),
  ),
  on(
    ProjectActions.updateProjectRADAction,
    ({ payload, state }: PayloadAndState<RadData[], RADState>) => ({
      RAD: [
        ...payload.map((payloadValue: RadData) => {
          const stateValue = state.RAD.find(
            (v: RadData) => v.RADField === payloadValue.RADField,
          );

          return {
            ...stateValue,
            ...payloadValue,
          };
        }),
      ],
    }),
  ),
);
