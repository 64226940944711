import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Coordinate,
  Input,
  PrimaryButton,
  Select,
  TextArea,
} from '@shared/modules';
import { DDVSelectors, LeasingSelectors } from '@store/selectors';

type DataType = ReturnType<
  typeof LeasingSelectors.getLeasingDetailsSummaryData
>['siteInfo'];

export const SiteInformation: FC<InformationProps<DataType>> = ({
  className,
  data,
}: PropsWithChildren<InformationProps<DataType>>) => {
  const [form] = Form.useForm();
  const { TowerOwnerDDV, TowerTypeDDV, EquipmentTypeDDV } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'TowerOwner',
      'TowerType',
      'EquipmentType',
    ]),
  );

  useEffect(() => {
    form.setFieldsValue(data!);
  }, [form, data]);

  return (
    <Panel header="Site Information" className={className}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="MDGLocationCode"
              label="MDG Location Code"
              isEditing={false}
            />
            <Input
              id="PSLocationCode"
              label="PS Location Code"
              isEditing={false}
            />
            <Input id="siteName" label="Site Name" isEditing={false} />
            <Input
              id="contractNumber"
              label="Contract Number"
              isEditing={false}
            />
            <Autocomplete
              id="towerOwner"
              label="Tower Owner"
              options={TowerOwnerDDV}
              isEditing={false}
            />
            <Input
              id="towerOwnerSiteID"
              label="Tower Owner Site ID"
              isEditing={false}
            />
            <Input
              id="streetAddress"
              label="Street Address"
              isEditing={false}
            />
            <Input id="city" label="City" isEditing={false} />
            <Input id="stateName" label="State" isEditing={false} />
            <Input id="zip" label="Zip" isEditing={false} />
          </Col>
          <Col span="12">
            <Input id="countyName" label="County" isEditing={false} />
            <Input
              id="jurisdictionName"
              label="Jurisdiction"
              isEditing={false}
            />
            <Coordinate id="latitude" label="Latitude" isEditing={false} />
            <Coordinate id="longitude" label="Longitude" isEditing={false} />
            <Select
              id="towerTypeId"
              label="Tower Type"
              options={TowerTypeDDV}
              isEditing={false}
            />
            <Input id="towerHeight" label="Tower Height" isEditing={false} />
            <Input
              id="leasedGroundSpace"
              label="Leased Ground Space"
              isEditing={false}
            />
            <Select
              id="equipmentTypeId"
              label="Equipment Type"
              options={EquipmentTypeDDV}
              isEditing={false}
            />
            <TextArea id="siteNote" label="Site Notes" isEditing={false} />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/site/${
              data?.PSLocationCode || data?.MDGLocationCode
            }/details`}
          />
        </Row>
      </Form>
    </Panel>
  );
};
