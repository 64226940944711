import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaceTaskType } from '@symfa-inc/providence-verizon-types';
import { Form, Row } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/es/interface';
import { HttpService, ObjectComparatorService } from '@core/services';
import { EngineeringHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  formDiffs,
  isValidForm,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  EditableComponentProps,
  EngineeringRegulatoryCompliance,
  EngineeringRFDSPhase1,
} from '@models/interfaces';
import { NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import {
  EngineeringSelectors,
  PaceTasksSelectors,
  ProjectFuzeIDsSelectors,
  UserSelectors,
} from '@store/selectors';
import {
  REGULATORY_COMPLIANCE_INFO_DATES,
  RFDS_INFO_DATES,
} from './models/constants';
import {
  ReferencePanel,
  RegulatoryCompliancePanel,
  RFDSPhase1Panel,
} from './components';
import { updateEngineeringPACETaskFields } from './helpers';

import './styles.scss';

const { useForm } = Form;

export const Workflow: FC<EditableComponentProps> = ({
  isEditing,
  toggleEditing,
  isActive,
}: PropsWithChildren<EditableComponentProps>) => {
  const dispatch = useDispatch();

  const [RFDSForm] = useForm();
  const [referenceForm] = useForm();
  const [regulatoryComplianceForm] = useForm();

  const currentPaceTasks = useSelector(PaceTasksSelectors.getPaceTasks);
  const {
    engineeringProjectData,
    engineeringWorkflowData,
    engineeringLeasingData,
    engineeringSiteData,
  } = useSelector(EngineeringSelectors.getWorkflowData);
  const { RFDSPhase1Fields, regulatoryComplianceFields } = useSelector(
    UserSelectors.getEngineeringBrowsePermissions,
  );
  const projectFuzeIDsTableVisible = useSelector(
    ProjectFuzeIDsSelectors.getProjectFuzeIDsTableVisible,
  );
  const engineeringFetching = useSelector(EngineeringSelectors.isFetching);

  const [workflowForm, setWorkflowForm] = useState<Array<Store>>([]);
  const [initialForm, setInitialForm] = useState<Array<Store>>([]);

  const {
    RFSSROrdered,
    RFSSRReceived,
    finalRFDSReceived,
    SOFIssued,
    PALOrNPAReceived,
  } = useSelector(
    PaceTasksSelectors.getMainPaceTaskDates(
      PaceTaskType.ActualDate,
      'RFSSROrdered',
      'RFSSRReceived',
      'finalRFDSReceived',
      'SOFIssued',
      'PALOrNPAReceived',
    ),
  );

  const { RBSDataEntered: CI004 } = useSelector(
    PaceTasksSelectors.getMainPaceTaskDates(
      PaceTaskType.PACEActualDate,
      'RBSDataEntered', // CI004
    ),
  );

  const arraysCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    workflowForm,
    true,
  );

  const buttonsDisabled = engineeringFetching || arraysCompare;

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!arraysCompare));
  }, [arraysCompare]);

  const [referencePanelData, setReferencePanelData] = useState({
    engineeringLeasingData: {
      ...engineeringLeasingData,
      SOFIssued,
    },
    engineeringSiteData,
    engineeringProjectData,
    currentPaceTasks,
  });
  const [RFDSPhase1PanelData, setRFDSPhase1PanelData] = useState({
    ...engineeringWorkflowData?.RFDSPhase1,
    finalRFDSReceived,
    CI004,
  });
  const [regulatoryCompliancePanelData, setRegulatoryCompliancePanelData] =
    useState({
      ...engineeringWorkflowData?.regulatoryCompliance,
      RFSSROrdered,
      RFSSRReceived,
      PALOrNPAReceived,
    });

  useEffect(() => {
    setReferencePanelData({
      engineeringLeasingData: {
        ...engineeringLeasingData,
        SOFIssued,
      },
      engineeringSiteData,
      engineeringProjectData,
      currentPaceTasks,
    });
  }, [
    engineeringLeasingData,
    SOFIssued,
    engineeringSiteData,
    engineeringProjectData,
    currentPaceTasks,
  ]);

  useEffect(() => {
    setRFDSPhase1PanelData({
      ...engineeringWorkflowData?.RFDSPhase1,
      finalRFDSReceived,
      CI004,
    });
  }, [engineeringWorkflowData?.RFDSPhase1, finalRFDSReceived, CI004]);

  useEffect(() => {
    setRegulatoryCompliancePanelData({
      ...engineeringWorkflowData?.regulatoryCompliance,
      RFSSROrdered,
      RFSSRReceived,
      PALOrNPAReceived,
    });
  }, [
    engineeringWorkflowData?.regulatoryCompliance,
    RFSSROrdered,
    RFSSRReceived,
    PALOrNPAReceived,
  ]);

  const onSubmit = async (): Promise<void> => {
    if (
      (await isValidForm(RFDSForm)) &&
      (await isValidForm(regulatoryComplianceForm))
    )
      try {
        const [RFDSInitial, regulatoryComplianceInitial] = initialForm;
        const [RFDS, regulatoryCompliance] = workflowForm;

        await HttpService.getHttpRequests(
          EngineeringHttpService,
        ).updateEngineering(engineeringProjectData?.id, {
          RFDSPhase1: formDiffs<EngineeringRFDSPhase1>(
            momentizeObjectDates<EngineeringRFDSPhase1>(
              RFDSInitial as EngineeringRFDSPhase1,
              RFDS_INFO_DATES,
              true,
            ),
            momentizeObjectDates<EngineeringRFDSPhase1>(
              RFDS as EngineeringRFDSPhase1,
              RFDS_INFO_DATES,
              true,
            ),
          ),
          regulatoryCompliance: formDiffs<EngineeringRegulatoryCompliance>(
            momentizeObjectDates<EngineeringRegulatoryCompliance>(
              regulatoryComplianceInitial as EngineeringRegulatoryCompliance,
              REGULATORY_COMPLIANCE_INFO_DATES,
              true,
            ),
            momentizeObjectDates<EngineeringRegulatoryCompliance>(
              regulatoryCompliance as EngineeringRegulatoryCompliance,
              REGULATORY_COMPLIANCE_INFO_DATES,
              true,
            ),
          ),
        });

        updateEngineeringPACETaskFields(
          momentizeObjectDates<EngineeringRegulatoryCompliance>(
            regulatoryCompliance as EngineeringRegulatoryCompliance,
            REGULATORY_COMPLIANCE_INFO_DATES,
            true,
          ),
          momentizeObjectDates<EngineeringRFDSPhase1>(
            RFDS as EngineeringRFDSPhase1,
            RFDS_INFO_DATES,
            true,
          ),
        );

        setInitialForm(workflowForm);

        toggleEditing?.();

        NotificationsLoader.notificationSuccess(
          `Information has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
  };

  const onCancel = (): void => {
    const [RFDS, regulatoryCompliance] = initialForm;

    RFDSForm?.setFieldsValue(
      momentizeObjectDates<EngineeringRFDSPhase1>(
        RFDS as EngineeringRFDSPhase1,
        RFDS_INFO_DATES,
      ),
    );
    regulatoryComplianceForm?.setFieldsValue(
      momentizeObjectDates<EngineeringRegulatoryCompliance>(
        regulatoryCompliance as EngineeringRegulatoryCompliance,
        REGULATORY_COMPLIANCE_INFO_DATES,
      ),
    );

    setWorkflowForm(initialForm);

    toggleEditing?.();
  };

  const onValuesChange = (): void => {
    if (Object.keys(RFDSForm.getFieldsValue()).length) {
      const data = [
        RFDSForm.getFieldsValue(),
        regulatoryComplianceForm.getFieldsValue(),
      ];

      setWorkflowForm(data);

      if (!Object.keys(initialForm).length) {
        setInitialForm(data);
      }
    }
  };

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isActive, isEditing]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-engineering-workflow ">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'fuze-ids-table-open': projectFuzeIDsTableVisible,
        })}
      >
        <div>
          <ReferencePanel data={referencePanelData} form={referenceForm} />
          <RFDSPhase1Panel
            permissions={RFDSPhase1Fields}
            isEditing={isEditing}
            data={RFDSPhase1PanelData}
            form={RFDSForm}
            onValuesChange={onValuesChange}
          />
          <RegulatoryCompliancePanel
            permissions={regulatoryComplianceFields}
            isEditing={isEditing}
            data={regulatoryCompliancePanelData}
            form={regulatoryComplianceForm}
            onValuesChange={onValuesChange}
          />
        </div>
      </div>
      {isEditing && (
        <Row className="prov-site-information__btn-wrap">
          <div>
            <PrimaryButton
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmit}
            />
            <PrimaryButton
              title="Cancel"
              type="default"
              disabled={buttonsDisabled}
              onClick={onCancel}
            />
          </div>
        </Row>
      )}
    </div>
  );
};
