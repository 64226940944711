import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  EquipmentHttpService,
  ScopingHttpService,
  SiteHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';
import store from '@store/index';
import { EngineeringScopingState } from '@store/reducers/engineering/scoping.reducer';

export class ScopingDetailsResolver implements Resolver {
  private static get _state(): EngineeringScopingState {
    return store.getState()?.engineering?.scoping || [];
  }

  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await HttpService.getHttpRequests(ScopingHttpService).getScoping(
      projectBundleID,
    );
    await HttpService.getHttpRequests(
      SiteHttpService,
    ).getPSLocationCodeOptions();
    await HttpService.getHttpRequests(EquipmentHttpService).getEquipmentTypes();

    if (ScopingDetailsResolver._state?.equipmentTypeNames?.length) {
      await HttpService.getHttpRequests(
        EquipmentHttpService,
      ).getEquipmentsByTypes(ScopingDetailsResolver._state?.equipmentTypeNames);
    }
  }
}
