import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { ACTUAL_DATE } from '@core/utils/validators';
import { YES_NO_GROUP } from '@models/constants';
import { EditableInformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  Input,
  InputNumber,
  RadioGroup,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';

export const ProjectInformation: FC<EditableInformationProps> = ({
  isEditing,
  data,
  form,
  onValuesChange,
  permissions: {
    RADA,
    RADB,
    RADG,
    RADD,
    azimuthA,
    azimuthB,
    azimuthG,
    azimuthD,
    mountTypes,
    civilVendor,
    isCraneRequired,
    craneHeight,
    scopingComplete,
    finalRFDSReceived,
  },
}: EditableInformationProps) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(data);
    }
  }, []);

  return (
    <Panel header="Project Information">
      <Form
        wrapperCol={{ span: 14 }}
        labelCol={{ span: 12 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span="12">
            <Input id="projectType" label="Project Type" isEditing={false} />
            <DatePicker
              id="scopingComplete"
              label="Scoping Complete"
              isEditing={isEditing && scopingComplete}
              formItemProps={{
                rules: ACTUAL_DATE(data?.scopingComplete),
              }}
            />
            <DatePicker
              id="finalRFDSReceived"
              label="Final RFDS Date"
              isEditing={isEditing && finalRFDSReceived}
              formItemProps={{
                rules: ACTUAL_DATE(data?.finalRFDSReceived),
              }}
            />
            <Autocomplete
              id="mountTypes"
              label="Mount Type"
              isEditing={isEditing && mountTypes}
              options={dropdownsOptions?.MountModel}
            />
            <RadioGroup
              id="isCraneRequired"
              label="Crane Required"
              isEditing={isEditing && isCraneRequired}
              buttons={YES_NO_GROUP}
            />
            <InputNumber
              id="craneHeight"
              label="Crane Height"
              isEditing={isEditing && craneHeight}
            />
            <Autocomplete
              id="civilVendor"
              label="Civil Vendor"
              isEditing={isEditing && civilVendor}
              options={dropdownsOptions?.CivilVendor}
            />
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <InputNumber
              id="azimuthA"
              label="Azimuth A"
              isEditing={isEditing && azimuthA}
            />
            <InputNumber
              id="azimuthB"
              label="Azimuth B"
              isEditing={isEditing && azimuthB}
            />
            <InputNumber
              id="azimuthG"
              label="Azimuth G"
              isEditing={isEditing && azimuthG}
            />
            <InputNumber
              id="azimuthD"
              label="Azimuth D"
              isEditing={isEditing && azimuthD}
            />
          </Col>
          <Col span="12">
            <InputNumber
              id="RADA"
              label="RAD A"
              isEditing={isEditing && RADA}
            />
            <InputNumber
              id="RADB"
              label="RAD B"
              isEditing={isEditing && RADB}
            />
            <InputNumber
              id="RADG"
              label="RAD G"
              isEditing={isEditing && RADG}
            />
            <InputNumber
              id="RADD"
              label="RAD D"
              isEditing={isEditing && RADD}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
