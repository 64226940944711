import React, {
  forwardRef,
  PropsWithChildren,
  Ref,
  useImperativeHandle,
  useRef,
} from 'react';
import { ProjectType } from '@symfa-inc/providence-verizon-types';
import { Col, Form, Row } from 'antd';
import { YES_NO_GROUP } from '@models/constants';
import { SiteInformationType } from '@models/types';
import {
  Autocomplete,
  DatePicker,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { SiteInformationControlsProps } from '../../models/interfaces';
import {
  ModControlsRefType,
  SiteInformationFormRefType,
} from '../../models/types';
import { ModControls } from './mod-controls';
import { onSiteInfoFormChange, onSiteInfoFormSubmit } from './models';
import { NSBControls } from './nsb-controls';

export const SiteInformationForm = forwardRef(
  (
    {
      form,
      data,
      projectType,
      isEditing,
      dropdownsOptions,
      permissions: {
        generalFields: {
          isSiteAuditRequired,
          scheduledSiteWalkDate,
          siteWalkCompleteDate,
          siteAuditCompletedBy,
          siteAuditNote,
          isFollowUpRequired,
          savedToFile,
          towerType,
          equipmentType,
        },
        ModFields,
        NSBFields,
      },
      setIsSiteInfoCompare,
    }: PropsWithChildren<SiteInformationControlsProps>,
    forwardedRef: Ref<SiteInformationFormRefType>,
  ) => {
    const ModControlsRef = useRef<ModControlsRefType>(null);

    const unknownSwitchState = ModControlsRef.current?.unknownSwitchState;

    useImperativeHandle(forwardedRef, () => ({
      onSubmit(): SiteInformationType {
        return onSiteInfoFormSubmit(
          form,
          data,
          projectType,
          unknownSwitchState,
        );
      },
      // getter and setter unknownSwitchState
      unknownSwitchState: !!unknownSwitchState,
      changeUnknownSwitchState(value: boolean): void {
        ModControlsRef.current?.changeUnknownSwitchState(value);
      },
      //
      setUnknownSwitch(): void {
        if (data?.isUnknownPowerPlantType !== undefined) {
          ModControlsRef.current?.setUnknownSwitch(
            data?.isUnknownPowerPlantType,
          );
        }
      },
    }));

    return (
      <Form
        wrapperCol={{ span: 13 }}
        labelCol={{ span: isEditing ? 8 : 11 }}
        form={form}
        onValuesChange={(): void => {
          onSiteInfoFormChange(
            form,
            data,
            setIsSiteInfoCompare,
            projectType,
            unknownSwitchState,
          );
        }}
      >
        <Row>
          <Col span="12">
            <RadioGroup
              id="isSiteAuditRequired"
              label="Site Audit Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!isSiteAuditRequired}
            />
            <DatePicker
              id="scheduledSiteWalkDate"
              label="Scheduled Site Walk"
              isEditing={isEditing && !!scheduledSiteWalkDate}
            />
            <DatePicker
              id="siteWalkCompleteDate"
              label="Site Walk Complete"
              isEditing={isEditing && !!siteWalkCompleteDate}
            />
            <Autocomplete
              id="siteAuditCompletedBy"
              label="Site Audit Completed By"
              options={dropdownsOptions?.SiteAuditCompletedBy}
              isEditing={isEditing && !!siteAuditCompletedBy}
            />
            <Autocomplete
              id="towerType"
              label="Tower Type"
              options={dropdownsOptions?.TowerType}
              isEditing={isEditing && !!towerType}
            />
            <Autocomplete
              id="equipmentType"
              label="Equipment Type"
              options={dropdownsOptions?.EquipmentType}
              isEditing={isEditing && !!equipmentType}
            />
            <TextArea
              id="siteAuditNote"
              label="Site Audit Note"
              isEditing={isEditing && !!siteAuditNote}
            />
            <RadioGroup
              id="isFollowUpRequired"
              label="Follow Up Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!isFollowUpRequired}
            />
            <DatePicker
              id="savedToFileDate"
              label="Saved to File"
              isEditing={isEditing && !!savedToFile}
            />
          </Col>
          <Col span="12">
            {projectType === ProjectType.Mod ? (
              <ModControls
                ref={ModControlsRef}
                isEditing={isEditing}
                permissions={ModFields}
                dropdownsOptions={dropdownsOptions}
              />
            ) : (
              <NSBControls
                isEditing={isEditing}
                permissions={NSBFields}
                dropdownsOptions={dropdownsOptions}
              />
            )}
          </Col>
        </Row>
      </Form>
    );
  },
);
