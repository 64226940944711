import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { mainDateFormat } from '@core/utils/methods';
import { FormProps, ProjectPaceTask } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Coordinate, Input, TextArea } from '@shared/modules';

export const ReferencePanel: FC<FormProps> = ({
  form,
  data: {
    engineeringLeasingData,
    engineeringSiteData,
    engineeringProjectData,
    currentPaceTasks,
  },
}: PropsWithChildren<FormProps>) => {
  useEffect(() => {
    const {
      state,
      county,
      jurisdiction,
      towerType,
      equipmentType,
      riskCategory,
      ...restData
    } = engineeringSiteData;

    form?.setFieldsValue({
      ...restData,
      state: state?.name,
      county: county?.name,
      jurisdiction: jurisdiction?.id,
      towerType: towerType?.value,
      equipmentType: equipmentType?.value,
      riskCategory: riskCategory?.value,
      SOFIssued: mainDateFormat(engineeringLeasingData?.SOFIssued),
      civilVendor: engineeringProjectData?.civilVendor?.value,
      scopingComplete: mainDateFormat(
        currentPaceTasks.find(
          (paceTask: ProjectPaceTask) => paceTask?.paceTaskID === 'SS014',
        )?.projectPaceTaskData?.forecastedDate,
      ),
    });
  }, [
    engineeringSiteData,
    engineeringLeasingData,
    engineeringProjectData,
    currentPaceTasks,
  ]);

  return (
    <Panel
      header="Reference"
      className="prov-engineering-workflow__panel reference-panel"
      collapsible
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }} form={form}>
        <Row>
          <Col span="10">
            <Input
              id="streetAddress"
              label="Street Address"
              isEditing={false}
            />
            <Input id="city" label="City" isEditing={false} />
            <Input id="state" label="State" isEditing={false} />
            <Input id="zip" label="Zip" isEditing={false} />
            <Coordinate id="latitude" label="Latitude" isEditing={false} />
            <Coordinate id="longitude" label="Longitude" isEditing={false} />
            <Input id="towerHeight" label="Tower Height" isEditing={false} />
          </Col>
          <Col span="12">
            <Input
              id="groundElevation"
              label="Ground Elevation"
              isEditing={false}
            />
            <Input id="towerType" label="Tower Type" isEditing={false} />
            <Input
              id="scopingComplete"
              label="Scoping Complete"
              isEditing={false}
            />
            <TextArea
              id="demotionNote"
              label="Demotion Notes"
              isEditing={false}
            />
            <TextArea
              id="promotionNote"
              label="Promotion Notes"
              isEditing={false}
            />
            <Input id="SOFIssued" label="SOF Issued Date" isEditing={false} />
            <Input id="civilVendor" label="Civil Vendor" isEditing={false} />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
