import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DropDownSelectorValues } from '@symfa-inc/providence-verizon-types';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Input, PrimaryButton, Select } from '@shared/modules';
import { DDVSelectors, LeasingSelectors } from '@store/selectors';

type DataType = ReturnType<
  typeof LeasingSelectors.getLeasingDetailsSummaryData
>['leasingInfo'];

export const LeasingInformation: FC<InformationProps<DataType>> = ({
  className,
  data,
}: PropsWithChildren<InformationProps<DataType>>) => {
  const [form] = Form.useForm();

  const structuralResultDDV = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(
      DropDownSelectorValues.StructuralResult,
    ),
  );

  useEffect(() => form.setFieldsValue(data!), [form, data]);

  return (
    <Panel header="Leasing Information" className={className}>
      <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} form={form}>
        <Row>
          <Col span="12">
            <Select
              id="structuralResultId"
              label="Structural Result"
              isEditing={false}
              options={structuralResultDDV}
            />
          </Col>
          <Col span="12">
            <Input id="leasingNote" label="Leasing Notes" isEditing={false} />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/leasing/${data!.projectBundleID}/details/workflow`}
          />
        </Row>
      </Form>
    </Panel>
  );
};
