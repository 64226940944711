import { ProjectType } from '@symfa-inc/providence-verizon-types';
import { AccountingBrowseFilter } from '@models/interfaces';
import { RadioButtonProps } from '@shared/modules/ui-kit/models/interfaces';

export const ModModeColumnsArray: string[] = [
  'SS010',
  'SS010InvoiceNumber',
  'SS010InvoiceDate',
  'RE066',
  'RE066InvoiceNumber',
  'RE066InvoiceDate',
  'towerOwner',
];

export const radioGroupButtons: RadioButtonProps[] = [
  { viewValue: ProjectType.Mod, value: ProjectType.Mod },
  { viewValue: ProjectType.NSB, value: ProjectType.NSB },
];

export const ACCOUNTING_DEFAULT_BROWSE_CONFIGURE_FILTER: AccountingBrowseFilter =
  {
    PSLocationCode: undefined,
    fuzeId: undefined,
    RE020ActualDate: undefined,
  };
