import React, {
  Dispatch,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-verizon-types';
import { Store } from 'antd/es/form/interface';
import Form, { useForm } from 'antd/lib/form/Form';
import { SelectValue } from 'antd/lib/select';
import editIcon from 'assets/icons/edit-icon.svg';
import { RuleObject } from 'rc-field-form/es/interface';
import { HttpService } from '@core/services';
import { VendorHttpService } from '@core/services/http';
import { toggleModal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import {
  AdminVendorDistributionListData,
  AdminVendorsData,
} from '@models/interfaces';
import { ScrollToInitRef } from '@models/types';
import {
  DeleteModal,
  NotificationsLoader,
  Panel,
  VirtualTable,
} from '@shared/components';
import { Autocomplete, Input, PrimaryButton } from '@shared/modules';
import { VendorsSelectors } from '@store/selectors';
import { VendorDistributionListModal, VendorsModal } from './components';
import {
  getAdminVendorsColumns,
  getAdminVendorsDistributionColumns,
  VendorListTableItem,
  VENDORS_DEFAULT_VALUE,
} from './models';

import './styles.scss';

export const Vendors: FC = () => {
  const [form] = useForm();

  const vendorService = useMemo(
    () => HttpService.getHttpRequests(VendorHttpService),
    [],
  );

  const virtualTableRef = useRef<ScrollToInitRef>(null);

  const { vendors, vendorsLists, vendorsListsOptions } = useSelector(
    VendorsSelectors.getVendorsMainData,
  );

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [vendorsDistributionModal, setVendorsDistributionModalVisible] =
    useState<boolean>(false);
  const [isAddEditVendorsModalVisible, setAddEditVendorsModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [selectedItem, setSelectedItem] = useState<VendorListTableItem>(
    VENDORS_DEFAULT_VALUE,
  );
  const [selectorValue, setSelectorValue] = useState<Undefinable<string>>();
  const [tableItems, setTableItems] = useState<AdminVendorsData[]>([]);

  const toggleAddAndEditVendorsModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditVendorsModalVisible(!isAddEditVendorsModalVisible);

    if (isAddEditVendorsModalVisible) {
      setSelectedItem(VENDORS_DEFAULT_VALUE);
    }
  };

  const toggleVendorsDistributionModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setVendorsDistributionModalVisible(!vendorsDistributionModal);
  };

  const selectCurrentItem = (
    currentItem: AdminVendorsData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(currentItem);
    setter(!prevState);
  };

  const onDelete = async (): Promise<void> => {
    if (selectorValue) {
      try {
        await vendorService.updateVendorListAssignments({
          vendorListId: selectorValue,
          vendorId: selectedItem.id,
          method: 'unassign',
        });

        NotificationsLoader.notificationSuccess(
          'Vendor has been removed from list!',
        );
      } catch (e) {
        console.error(e);
      }
    } else {
      await vendorService.deleteVendor(selectedItem.id);
    }

    setDeleteModalVisible(false);
    setSelectedItem(VENDORS_DEFAULT_VALUE);
  };

  const changeSelectorValue = (value: Undefinable<string>): void => {
    form.setFieldsValue({
      vendorList: value,
    });

    setSelectorValue(value);
  };

  useEffect(() => {
    if (selectorValue) {
      const currentList = vendorsLists.find(
        (list: AdminVendorDistributionListData) => list.id === selectorValue,
      );

      setTableItems(currentList ? currentList.vendors : []);
    }
  }, [selectorValue, vendorsLists]);

  return (
    <>
      <div className="prov-admin-vendors">
        <Panel header="Vendors" className="prov-admin-vendors__panel">
          <VirtualTable
            dataSource={vendors}
            columns={getAdminVendorsColumns(
              (vendor: AdminVendorsData): void =>
                selectCurrentItem(
                  vendor,
                  () => toggleAddAndEditVendorsModal(ModalMainTypes.Edit),
                  isAddEditVendorsModalVisible,
                ),
              (vendor: AdminVendorsData): void =>
                selectCurrentItem(
                  vendor,
                  setDeleteModalVisible,
                  deleteModalVisible,
                ),
            )}
            maxViewRow={5}
          />
          <PrimaryButton
            title="Add"
            icon="+"
            className="add-btn"
            onClick={(): void =>
              toggleAddAndEditVendorsModal(ModalMainTypes.Add)
            }
          />
        </Panel>
        <Panel
          header="Vendors Distribution Lists"
          className="prov-admin-vendors__panel distribution-panel"
        >
          <Form
            className="distribution-panel__handler"
            form={form}
            onFinish={async (value: Store): Promise<void> => {
              const currentVendor = vendors.find(
                (vendor: AdminVendorsData) => vendor.email === value.vendor,
              );

              if (currentVendor?.id) {
                try {
                  await vendorService.updateVendorListAssignments({
                    vendorListId: selectorValue as string,
                    vendorId: currentVendor.id,
                    method: 'assign',
                  });

                  NotificationsLoader.notificationSuccess(
                    'Vendor has been added to list!',
                  );

                  form.setFieldsValue({ vendor: undefined });
                } catch (e) {
                  console.error(e);
                }
              }
            }}
          >
            <Autocomplete
              label="List"
              id="vendorList"
              options={vendorsListsOptions}
              elementProps={{
                onChange: (value: SelectValue): void => {
                  changeSelectorValue(value as string);
                  virtualTableRef?.current?.scrollToInit();
                },
              }}
            />
            <PrimaryButton
              title="Edit"
              icon={<img src={editIcon} alt="Edit vendors list" />}
              onClick={(): void =>
                toggleVendorsDistributionModal(ModalMainTypes.Edit)
              }
              disabled={!selectorValue}
            />
            <PrimaryButton
              title="Add new vendors list "
              icon="+"
              className="distribution-panel_add-btn add-list-btn"
              onClick={(): void =>
                toggleVendorsDistributionModal(ModalMainTypes.Add)
              }
            />
            <Input
              label="Add Vendor"
              id="vendor"
              formItemProps={{
                rules: [
                  { type: 'email', message: 'This field must be an email!' },
                  {
                    validator: (
                      _: RuleObject,
                      inputValue: string,
                    ): Promise<void> =>
                      !vendors.some(
                        (vendor: AdminVendorsData) =>
                          vendor.email === inputValue,
                      )
                        ? Promise.reject(
                            new Error(
                              "There aren't any vendors with such email!",
                            ),
                          )
                        : Promise.resolve(),
                  },
                ],
              }}
            />
            <PrimaryButton
              title="Add"
              icon="+"
              className="distribution-panel_add-btn"
              disabled={!selectorValue}
              htmlType="submit"
            />
          </Form>
          <VirtualTable
            ref={virtualTableRef}
            dataSource={tableItems}
            columns={getAdminVendorsDistributionColumns(
              (vendorList: VendorListTableItem): void =>
                selectCurrentItem(
                  vendorList,
                  setDeleteModalVisible,
                  deleteModalVisible,
                ),
            )}
            maxViewRow={5}
          />
        </Panel>
      </div>
      <VendorsModal
        visible={isAddEditVendorsModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditVendorsModal}
        itemValue={selectedItem}
      />
      <VendorDistributionListModal
        visible={vendorsDistributionModal}
        modalType={currentModalType}
        toggleModal={toggleVendorsDistributionModal}
        selectorValue={selectorValue}
        setSelector={changeSelectorValue}
      />
      <DeleteModal
        onOk={onDelete}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
    </>
  );
};
