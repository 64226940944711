import { ReactText } from 'react';
import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-verizon-types';
import { createSelector } from 'reselect';
import { momentizeObjectDates } from '@core/utils/methods';
import {
  EngineeringSOFProjectData,
  OtherEquipmentData,
  ProjectPaceTask,
} from '@models/interfaces';
import { SectorDataState } from '@models/types';
import { AppState } from '../../reducers';
import { EngineeringBrowseState } from '../../reducers/engineering/browse.reducer';
import { PaceTasksState } from '../../reducers/pace-tasks.reducer';

const engineeringBrowseState = (state: AppState): EngineeringBrowseState =>
  state.engineering.browse;
const paceTasksState = (state: AppState): PaceTasksState => state.paceTasks;

interface SiteInfo {
  PSLocationCode?: string;
  MDGLocationCode?: string;
  siteName?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: number;
  latitude?: ReactText;
  longitude?: ReactText;
  towerOwner?: string;
  towerType?: string;
  towerHeight?: number;
  equipmentType?: string;
  jurisdiction?: string;
}

interface ScopingInfo {
  promotionNote?: string;
  scopingGroundNote?: string;
  scopingTowerNote?: string;
  demotionNote?: string;
  RFDSStatus?: string;
}

interface SOFData {
  currentProjectId: string;
  projectInfo: EngineeringSOFProjectData;
  siteInfo: SiteInfo;
  scopingInfo: ScopingInfo;
  scopingSectors: SectorDataState;
  otherEquipments: OtherEquipmentData[];
}

export namespace EngineeringSelectors {
  export const getBrowseData = createSelector(
    [engineeringBrowseState],
    (state: EngineeringBrowseState) => state.engineeringBrowseData,
  );

  export const getDetailsData = createSelector(
    [engineeringBrowseState],
    (state: EngineeringBrowseState) => state.engineeringSiteData,
  );

  export const getSummaryData = createSelector(
    [engineeringBrowseState],
    ({
      engineeringLeasingData,
      engineeringSiteData,
      engineeringProjectData,
      scopingInfo,
    }: EngineeringBrowseState) => ({
      engineeringLeasingData,
      engineeringSiteData,
      engineeringProjectData,
      scopingInfo,
    }),
  );

  export const getWorkflowData = createSelector(
    [engineeringBrowseState],
    ({
      engineeringProjectData,
      engineeringWorkflowData,
      engineeringLeasingData,
      engineeringSiteData,
    }: EngineeringBrowseState) => ({
      engineeringProjectData,
      engineeringWorkflowData,
      engineeringLeasingData,
      engineeringSiteData,
    }),
  );

  export const getSOFData = createSelector(
    [engineeringBrowseState, paceTasksState],
    (
      {
        RAD,
        scopingInfo,
        scopingSectors,
        engineeringLeasingData,
        engineeringProjectData,
        engineeringWorkflowData,
        engineeringSiteData,
        otherEquipments,
      }: EngineeringBrowseState,
      { currentPaceTasks }: PaceTasksState,
    ): SOFData => ({
      currentProjectId: engineeringProjectData.id,
      projectInfo: momentizeObjectDates(
        {
          RADA: RAD.A,
          RADB: RAD.B,
          RADG: RAD.G,
          RADD: RAD.D,
          azimuthA: engineeringLeasingData?.leaseAudit?.azimuthA,
          azimuthB: engineeringLeasingData?.leaseAudit?.azimuthB,
          azimuthG: engineeringLeasingData?.leaseAudit?.azimuthG,
          azimuthD: engineeringLeasingData?.leaseAudit?.azimuthD,
          projectType: engineeringProjectData.projectType.value,
          mountTypes: scopingInfo?.previousMountModels,
          civilVendor: engineeringProjectData?.civilVendor,
          isCraneRequired:
            engineeringWorkflowData?.regulatoryCompliance?.isCraneRequired,
          craneHeight:
            engineeringWorkflowData?.regulatoryCompliance?.craneHeight,
          scopingComplete: currentPaceTasks?.find(
            (paceTask: ProjectPaceTask) =>
              paceTask.id === MAIN_PACE_TASK_IDs.scopingComplete,
          )?.projectPaceTaskData?.actualDate,
          finalRFDSReceived: currentPaceTasks?.find(
            (paceTask: ProjectPaceTask) =>
              paceTask.id === MAIN_PACE_TASK_IDs.finalRFDSReceived,
          )?.projectPaceTaskData?.actualDate,
        } as EngineeringSOFProjectData,
        ['scopingComplete', 'finalRFDSReceived'],
      ),
      siteInfo: {
        PSLocationCode: engineeringSiteData?.PSLocationCode,
        MDGLocationCode: engineeringSiteData?.MDGLocationCode,
        siteName: engineeringSiteData?.siteName,
        streetAddress: engineeringSiteData?.streetAddress,
        city: engineeringSiteData?.city,
        state: engineeringSiteData?.state?.name,
        zip: engineeringSiteData?.zip,
        latitude: engineeringSiteData?.latitude,
        longitude: engineeringSiteData?.longitude,
        towerOwner: engineeringSiteData?.towerOwner as any as string,
        towerType: engineeringSiteData?.towerType?.value,
        towerHeight: engineeringSiteData?.towerHeight,
        equipmentType: engineeringSiteData?.equipmentType?.value,
        jurisdiction: engineeringSiteData?.jurisdiction?.jurisdictionName,
      },
      scopingInfo: {
        promotionNote: scopingInfo?.promotionNote,
        scopingGroundNote: scopingInfo?.scopingGroundNote,
        scopingTowerNote: scopingInfo?.scopingTowerNote,
        demotionNote: scopingInfo?.demotionNote,
        RFDSStatus: engineeringWorkflowData?.RFDSPhase1?.RFDSStatus,
      },
      scopingSectors,
      otherEquipments,
    }),
  );

  export const getProjectType = createSelector(
    [engineeringBrowseState],
    (state: EngineeringBrowseState) =>
      state.engineeringProjectData.projectType.value,
  );

  export const getProjectId = createSelector(
    [engineeringBrowseState],
    (state: EngineeringBrowseState) => state.engineeringProjectData.id,
  );

  export const isFetching = createSelector(
    [engineeringBrowseState],
    (state: EngineeringBrowseState) => state.isFetching,
  );
}
