import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { linkElementRender } from '@permitting/jurisdiction/details/helpers';
import { DropDownSelectorValues } from '@symfa-inc/providence-verizon-types';
import {
  getDDVByIdOrUndefined,
  getStateByIdOrUndefined,
} from '@core/utils/methods';
import {
  TableContainer as ContentContainer,
  ViewElement,
} from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { JurisdictionInformationProps } from '../../models';

export const JurisdictionInformation: FC<JurisdictionInformationProps> = ({
  data,
}: PropsWithChildren<JurisdictionInformationProps>) => (
  <ContentContainer header="Jurisdiction Information">
    <div className="information">
      <ViewElement
        additionClassName="left"
        label="Jurisdiction Name"
        value={data?.jurisdictionName}
      />
      <ViewElement
        additionClassName="right"
        label="Malling Address"
        value={data?.mailingStreetAddress}
      />
      <ViewElement
        additionClassName="left"
        label="Link to Online Submittal"
        element={linkElementRender(data?.linkToOnlineSubmittal) as ReactElement}
      />
      <ViewElement additionClassName="right" label="City" value={data?.city} />
      <ViewElement
        additionClassName="left"
        label="Jurisdiction Notes"
        value={data?.jurisdictionNote}
      />
      <ViewElement
        additionClassName="right"
        label="State"
        value={getStateByIdOrUndefined(data?.state)?.name}
      />
      <ViewElement
        additionClassName="left"
        label="Zoning Required"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.ZoningRequired,
            data?.zoning?.zoningRequired,
          )?.value
        }
      />
      <ViewElement additionClassName="right" label="Zip" value={data?.zip} />
      <ViewElement
        additionClassName="left"
        label="Describe Overall Process"
        value={data?.zoning?.describeOverallProcess}
      />
      <ViewElement
        additionClassName="left"
        label="Estimated Zoning Approval Timeline"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.EstimatedZoningApprovalTimeline,
            data?.zoning?.estimatedZoningApprovalTimeline,
          )?.value
        }
      />
      <ViewElement
        additionClassName="left"
        label="Permit Required"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.PermittingRequired,
            data?.permitting?.permittingRequired,
          )?.value
        }
      />
      <ViewElement
        additionClassName="left"
        label="Permitting Notes"
        value={data?.permitting?.jurisdictionPermittingNote}
      />
    </div>
    <PrimaryButton
      className="action-button"
      title="View Details"
      target="_blank"
      href={`/permitting/jurisdiction/${data?.jurisdictionName}/details`}
      disabled={!data?.id}
    />
  </ContentContainer>
);
